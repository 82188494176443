<template>
  <div class="longBar cetralize auth-v2 justify-content-center align-items-center">
    <div class="m-2">
      <b-row>
        <b-col
          cols="9"
          sm="8"
          md="10"
          lg="12"
          class="px-xl-2 mx-auto m-3"
        >
          <b-img
            fluid
            center
            :src="logoImg"
            width="350"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="10"
          md="11"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card
            class="justify-content-md-center p-1 px-sm-2 px-md-3"
          >
            <b-card-title
              class="font-weight-bolder text-primary m-0 mb-1"
            >
              Bem-vindo!
            </b-card-title>
            <b-card-sub-title
              sub-title-tag="p"
              sub-title-text-variant="black"
            >
              Insira seus dados de usuário para acessar a plataforma
            </b-card-sub-title>
            <b-card-body
              class="p-0 pb-0"
            >
              <b-row class="justify-content-md-center mb-2">
                <!-- Login-->
                <b-col>
                  <!-- form -->
                  <validation-observer ref="loginValidation">
                    <b-form
                      class="auth-login-form mt-2"
                      @submit.prevent
                    >
                      <!-- email -->
                      <b-form-group
                        label="Email"
                        label-for="login-email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="login-email"
                            v-model="userEmail"
                            :state="errors.length > 0 ? false:null"
                            name="login-email"
                            placeholder="john@example.com"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >
                            Insira aqui o seu email.
                          </small>

                        </validation-provider>
                      </b-form-group>

                      <!-- forgot password -->
                      <b-form-group
                        label="Senha"
                        label-for="login-password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-form-input
                              id="login-password"
                              v-model="password"
                              :state="errors.length > 0 ? false:null"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              name="login-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >
                            Insira aqui a sua senha.
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <!-- checkbox -->
                      <b-form-group>
                        <b-form-checkbox
                          id="remember-me"
                          v-model="keep_connected"
                          name="checkbox-1"
                        >
                          Mantenha-me conectado
                        </b-form-checkbox>
                      </b-form-group>

                      <!-- submit buttons -->
                      <b-button
                        id="btn_login"
                        type="submit"
                        variant="primary"
                        block
                        @click="validationForm"
                      >
                        Entrar
                      </b-button>
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-card-sub-title
                    sub-title-tag="p"
                    sub-title-text-variant="black"
                    class="sub_title_2"
                  >
                    Para primeiro acesso contate o administrador do sistema
                  </b-card-sub-title>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCard,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { updateAbility } from '@/libs/acl/config'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      keep_connected: false,
      password: '',
      userEmail: '',
      uuid: '',
      logoImg: require('@/assets/images/logo/logo_login.svg'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    submitForm() {
      syslic
        .authentication
        .login(this.userEmail, this.password, this.keep_connected)
        .then(() => {
          this.$ability.update(updateAbility())
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login realizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'bidding' })
        })
        .catch(error => {
          let textError = 'Não foi possível fazer o login na sua conta, por favor verifique se o seu email e senha estão corretos.'

          if ('data' in error) {
            if (error.data.includes('Account Inactive')) {
              textError = 'A conta que este usuário é vinculado, está inativa.'
            }
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Falha no login.',
              text: textError,
              icon: 'EditIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.cetralize {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.longBar {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: linear-gradient(25deg, #001630 37%, #114078 70%, #072951 90%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .sub_title_2 {
    font-size: 11.5px;
    text-align: center
  }
</style>
